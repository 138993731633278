import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Rating,
} from "@mui/material";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";

const SurveyForm = () => {
  const [rating, setRating] = useState(null);

  const customIcons = {
    1: {
      icon: <SentimentVeryDissatisfiedIcon color="error" style={{ fontSize: "50px" }} />,
      label: "Very Dissatisfied",
    },
    2: {
      icon: <SentimentDissatisfiedIcon color="error" style={{ fontSize: "50px" }} />,
      label: "Dissatisfied",
    },
    3: {
      icon: <SentimentSatisfiedIcon  color="warning"  style={{ fontSize: "50px" }} />,
      label: "Neutral",
    },
    4: {
      icon: <SentimentSatisfiedAltIcon color="success" style={{ fontSize: "50px" }} />,
      label: "Satisfied",
    },
    5: {
      icon: <SentimentVerySatisfiedIcon color="success" style={{ fontSize: "50px" }} />,
      label: "Very Satisfied",
    },
  };

  const IconContainer = ({ value, ...props }) => (
    <span {...props}>{customIcons[value]?.icon}</span>
  );

  return (
    <Box
      sx={{
        background: "linear-gradient(135deg, #8B0000, black)",
        minHeight: "100vh",
        py: 6,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container
        maxWidth="sm"
        sx={{
          backgroundColor: "white",
          borderRadius: "20px",
          boxShadow: "0 8px 20px rgba(0, 0, 0, 0.3)",
          p: 5,
        }}
      >
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{ color: "darkred", fontWeight: "bold", mb: 3 }}
        >
          Student Event Feedback
        </Typography>
        <Box component="form" noValidate>
          {/* Name Field */}
          <TextField
            fullWidth
            label="Name"
            variant="outlined"
            margin="normal"
          />
          {/* Grade Field */}
          <FormControl fullWidth margin="normal">
            <InputLabel id="grade-label">Grade</InputLabel>
            <Select labelId="grade-label" label="Grade">
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={11}>11</MenuItem>
              <MenuItem value={12}>12</MenuItem>
            </Select>
          </FormControl>
          {/* School Field */}
          <TextField
            fullWidth
            label="School"
            variant="outlined"
            margin="normal"
          />
          {/* Satisfaction Level */}
          <Typography variant="h6" sx={{ mt: 3, mb: 2}}>
            1) What is your level of satisfaction attending the event?
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: 3,
            }}
          >
            <Rating
              name="customized-icons"
              value={rating}
              onChange={(event, newValue) => setRating(newValue)}
              IconContainerComponent={IconContainer}
              sx={{
                "& .MuiRating-iconEmpty": {
                  color: "#ccc",
                },
                fontSize: "3rem",
              }}
            />
          </Box>
          {/* Beneficial/Needs */}
          <Typography variant="h6" sx={{ mt: 3, mb: 1, color: "black" }}>
            2) Was it beneficial and met your needs?
          </Typography>
          <TextField
            fullWidth
            label="Your Answer"
            multiline
            rows={2}
            variant="outlined"
          />
          {/* What did you enjoy the most */}
          <Typography variant="h6" sx={{ mt: 3, mb: 1, color: "black" }}>
            3) What did you enjoy the most about the event?
          </Typography>
          <TextField
            fullWidth
            label="Your Answer"
            multiline
            rows={2}
            variant="outlined"
          />
          {/* Suggestions for Future */}
          <Typography variant="h6" sx={{ mt: 3, mb: 1, color: "black" }}>
            4) What would you like to see more at EduGate's next event?
          </Typography>
          <TextField
            fullWidth
            label="Your Suggestions"
            multiline
            rows={2}
            variant="outlined"
          />
          {/* Submit Button */}
          <Button
            variant="contained"
            fullWidth
            sx={{
              mt: 4,
              backgroundColor: "darkred",
              color: "white",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#8B0000",
              },
            }}
          >
            Submit
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default SurveyForm;
